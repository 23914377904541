/* eslint-disable import/prefer-default-export */
export const SEO_ITEMS = {
  defaultDescription: ``,
  aboutPage: {
    description: ``,
    title: 'Construction Guide - Про нас',
  },
  catalogPage: {
    description: ``,
    title: 'Construction Guide - Каталог',
  },
  contactsPage: {
    description: ``,
    title: 'Construction Guide - Контакти',
  },
  deliveryPage: {
    description: ``,
    title: 'Construction Guide - Доставка',
  },
  indexPage: {
    description: ``,
    title: 'Construction Guide - Головна',
  },
  partnersPage: {
    description: ``,
    title: 'Construction Guide - Наші партнери',
  },
  stocksPage: {
    description: ``,
    title: 'Construction Guide - Акції',
  },
  vacanciesPage: {
    description: ``,
    title: 'Construction Guide - Вакансії',
  },
  solutionsPage: {
    description: ``,
    title: 'Construction Guide - Будівельні Рішення',
  },
  guarantyPage: {
    description: ``,
    title: 'Construction Guide - Гарантія та обмін',
  },
  instructionPage: {
    description: ``,
    title: 'Construction Guide - Інструкція для юридичних осіб',
  },
  publicOfferPage: {
    description: ``,
    title: 'Construction Guide - Договір публічної оферти',
  },
  securityPage: {
    description: ``,
    title: 'Construction Guide - Політика безпеки інтернет-магазину будматеріалів',
  },
};
